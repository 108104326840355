module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://77a8eebb0591402590e0c2fad325de32@sentry.io/1865169","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Паломническо-туристический портал «Серафимова земля»","short_name":"Серафим","start_url":"/","background_color":"#3f2513","theme_color":"#3f2513","display":"standalone","legacy":"true","icon":"src/images/logo.png","cache_busting_mode":"none","icons":[{"src":"static/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"static/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"static/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"static/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"static/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"static/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"static/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"static/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"static/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"static/icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"include_favicon":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
